.chatbot {
  background-color: white;
  height: 60vh;
  max-height: 750px;
  width: 460px;
  position: fixed;
  bottom: 25px;
  right: 150px;
  margin: auto;
  border: 3px solid grey;
  z-index: 999;
}

.show-chat-btn {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: fixed;
  bottom: 35px;
  right: 35px;
  outline: none;
  background-color: #1982fc;
}

.show-chat-icon {
  font-size: 48px;
}

.chatbot-image {
  transform: translateY(5px);
}

.chatbot-header {
  display: none;
}

.chatbot {
  background: url(/images/loader.gif) center center no-repeat;
  background-size: 50px;
  background-color: white;
}

/* mobile + small screen */
@media screen and (max-width: 480px) {
  .chatbot {
    overflow: hidden;
    height: 95%;
    max-height: 100%;
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    border: none;
  }

  .chatbot-header {
    display: block;
    position: fixed;
    top: 0;
    height: 40px;
    width: 100%;
    border: 0;
    z-index: 999;
    background-color: #1982fc;
  }

  .mobile-close-chat-btn {
    float: left;
    height: 30px;
    padding: 5px 0 5px 16px;
    font-size: 16px;
    text-align: left;
    z-index: 999;
    background-color: transparent;
    margin: auto;
  }

  .show-chat-icon {
    font-size: 24px;
  }

  .show-chat-btn {
    height: 60px;
    width: 60px;
  }

  .tooltip {
    display: none;
  }
}
